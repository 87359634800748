import * as React from 'react';
import { graphql, Link } from 'gatsby';

import * as styles from './EllipsisList.css';

type Props = {
  region: GatsbyTypes.EllipsisList_slugFragment,
  open: boolean,
  closeEllipsisList: VoidFunction,
};

export const fragments = graphql`
fragment EllipsisList_slug on MarketplaceRegion {
  slug
}
`;

const EllipsisList: React.FC<Props> = ({
  region: {
    slug,
  },
  open,
  closeEllipsisList,
}) => {
  const improveURL = 'karrot://web/ad/user_surveys/7595';

  const popoverRef = React.useRef<HTMLDivElement>(null);

  const onClose = (event: any) => {
    event.stopPropagation();
    if (!popoverRef.current?.contains(event.target) && open) {
      closeEllipsisList();
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', onClose);
    window.addEventListener('touchstart', onClose);

    return () => {
      window.addEventListener('click', onClose);
      window.addEventListener('touchstart', onClose);
    };
  });

  return (
    <>
      {open && <div className={styles.root} ref={popoverRef}>
        <ul className={styles.ellipsisList}>
          <li
            className={styles.item}
            onClick={closeEllipsisList}
          >
            <Link
              className={styles.link}
              to={`/kr/${slug}/closed-partners/`}
              state={{ fromList: true }}
            >
              종료된 오픈알림
            </Link>
          </li>
          <li
            className={styles.item}
            onClick={closeEllipsisList}
          >
            <a
              className={styles.link}
              href={improveURL}
            >
              개선 제안하기
            </a>
          </li>
        </ul>
      </div>}
    </>
  );
};

export default EllipsisList;
