import * as React from 'react';

import * as styles from './TagGroup.css';

type Tag = {
  startIcon?: React.ReactNode,
  endIcon?: React.ReactNode,
  text: string,
};

type Props = {
  tags: Tag[],
  hightlightFirst?: boolean,
};

const TagGroup: React.FC<Props> = ({
  tags,
  hightlightFirst = false,
}) => {
  return (
    <ul className={styles.root}>
      {tags.map((tag, i) => (
        <li
          key={i}
          className={styles.tag({
            hightlight: i === 0 && hightlightFirst,
          })}
        >
          {tag.startIcon}
          <span>{tag.text}</span>
          {tag.endIcon}
        </li>
      ))}
    </ul>
  );
};

export default TagGroup;
