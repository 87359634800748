import * as React from 'react';

import * as styles from './Placeholder.css';

const Placeholder: React.FC = ({
  children,
}) => {
  return (
    <div className={styles.root}>
      <span className={styles.message}>
        {children}
      </span>
    </div>
  );
};

export default Placeholder;
