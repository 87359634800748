import * as React from 'react';
import { graphql } from 'gatsby';
import { Condition } from '@cometjs/core';

import Logo from './RegionPartnerList_MiniappEntry_Logo';
import TagGroup from './TagGroup';

import * as styles from './RegionPartnerList_MiniappEntry.css';

type Props = {
  miniapp: GatsbyTypes.RegionPartnerList_MiniappEntry_miniappFragment,
};

export const fragments = graphql`
  fragment RegionPartnerList_MiniappEntry_miniapp on MarketplaceMiniapp {
    ...RegionPartnerList_MiniappEntry_Logo_miniapp
    name
    description
    averageReviewScore
    totalSubscriptionCount
  }
`;

const RegionPartnerList_MiniappEntry: React.FC<Props> = ({
  miniapp,
  miniapp: {
    name,
    description,
    averageReviewScore,
    totalSubscriptionCount,
  },
}) => {
  const formatter = new Intl.NumberFormat('kr');

  return (
    <article className={styles.root}>
      <Logo miniapp={miniapp} />
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>
            {name}
          </h2>
          <p className={styles.description}>
            {description}
          </p>
        </div>
        <TagGroup
          tags={[
            averageReviewScore != null && {
              text: `평점 ${averageReviewScore.toFixed(1)}점`,
            },
            {
              text: `${formatter.format(totalSubscriptionCount)}명`,
            },
          ].filter(Condition.isTruthy)}
        />
      </div>
    </article>
  );
};

export default RegionPartnerList_MiniappEntry;
