import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { mapUnion } from '@cometjs/graphql-utils';

import MiniappEntry from './RegionPartnerList_MiniappEntry';
import PreRegistrationEntry from './RegionPartnerList_PreRegistrationEntry';

import * as styles from './RegionPartnerList.css';

type Props = {
  region: GatsbyTypes.RegionPartnerList_regionFragment,
};

export const fragments = graphql`
  fragment RegionPartnerList_region on MarketplaceRegion { 
    entries {
      __typename
      ...RegionPartnerList_MiniappEntry_miniapp
      ...RegionPartnerList_PreRegistrationEntry_preRegistration
      ...on MarketplaceMiniapp {
        miniId
        entryUrl
      }
      ...on MarketplaceMiniappPreRegistration {
        miniId
        slug
      }
    }
  }
`;

const RegionPartnerList: React.FC<Props> = ({
  region: {
    entries,
  },
}) => {
  return (
    <ul className={styles.root}>
      {entries.map(entry => (
        mapUnion(entry, {
          MarketplaceMiniapp: miniapp => (
            <li
              key={miniapp.miniId}
              className={styles.item}
            >
              <a
                className={styles.link}
                href={miniapp.entryUrl}
              >
                <MiniappEntry
                  miniapp={miniapp}
                />
              </a>
            </li>
          ),
          MarketplaceMiniappPreRegistration: preRegistration => (
            <li
              key={preRegistration.miniId}
              className={styles.item}
            >
              <Link
                className={styles.link}
                to={`/kr/partner-pre/${preRegistration.slug}/`}
                state={{ fromList: true }}
              >
                <PreRegistrationEntry
                  preRegistration={preRegistration}
                />
              </Link>
            </li>
          ),
        })
      ))}
    </ul>
  );
};

export default RegionPartnerList;
