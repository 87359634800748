import * as React from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';

import { required } from '@cometjs/core';

import Layout from '../components/Layout';
import AppBar from '../components/AppBar';
import RegionPartnerList from '../components/RegionPartnerList';
import WebViewAttachment from '../components/WebViewAttachment';
import EllipsisList from '../components/EllipsisList';
import Placeholder from '../components/Placeholder';

import { ReactComponent as Logo } from '../assets/images/logo_mini.svg';
import { ReactComponent as MoreVertIcon } from '@karrotmarket/karrot-ui-icon/svg/icon_more_vert_regular.svg';

import * as styles from './RegionPartnerListPage.css';

type Props = PageProps<GatsbyTypes.RegionPartnerListPageQuery>;

export const query = graphql`
  query RegionPartnerListPage(
    $regionPublicId: String!
  ) {
    marketplaceRegion(
      publicId: { eq: $regionPublicId }
    ) {
      ...RegionPartnerList_region
      ...EllipsisList_slug
      name
      publicId
    }
  }
`;

const RegionPartnerListPage: React.FC<Props> = ({
  data: {
    marketplaceRegion,
  },
}) => {
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);

  required(marketplaceRegion);

  return (
    <Layout>
      <div className={styles.root}>
        <AppBar
          title={(
            <div className={styles.logo}>
              <Logo />
            </div>
          )}
          action3={<MoreVertIcon
            onClick={(e) => {
              e.stopPropagation();
              setOpenPopover((prev) => !prev);
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
          />}
        />
        <main className={styles.appbox}>
          {marketplaceRegion.entries.length
            ? <RegionPartnerList region={marketplaceRegion} />
            : <Placeholder>
              이용 가능한 서비스가 없어요
            </Placeholder>
          }
        </main>
        <EllipsisList
          region={marketplaceRegion}
          open={openPopover}
          closeEllipsisList={() => {
            setOpenPopover(false);
          }}
        />
      </div>
      <WebViewAttachment />
    </Layout>
  );
};

export default RegionPartnerListPage;
